.testimonial-slider-item{
  width: 80%;
  margin:0 auto;
  position:relative;
  margin-bottom: 30px;
  padding-bottom: 30px;

  .testimonial-slider-item-content{
    font-size: 16px;
    font-weight: 300;
    float:left;
    display: block;
    overflow: hidden;
   // width:70%;
  }

  .testimonial-slider-item-image{
    display: block;
    float:left;
    margin-right:30px;
    max-width: 150px;
    border-radius:50%;
    overflow: hidden;
   // width:20%;
  }
  .testimonial-slider-item-author{

    display:block;
    float:right;
    font-size: 24px;
    font-weight: 300;
  }
}


.testimonials-wrapper{
  width:100%;
  margin-top:20px;
}
.testimonial-item{

  margin:0 auto;
  position:relative;
  margin-bottom: 20px;

  .testimonial-item-content{
    font-size: 16px;
    font-weight: 300;
    display: block;
    overflow: hidden;
    // width:70%;
  }

  .testimonial-item-image{
    display: block;
    float:right;
    margin-right:15px;
    max-width: 60px;
    border-radius:50%;
    overflow: hidden;
    margin-top:-20px;
    // width:20%;
  }
  .testimonial-item-author{

    display:block;
    float:right;
    font-size: 24px;
    font-weight: 300;
  }
}

